/* 
1.  svg is not fisible if Flex is the container 
2.  needs a width
*/
.svg-not-text-select {
    /* cursor: default; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.svg {
    width: 70vh;
}

@media screen and (max-width: 768px) {

    /* Apply to screen with less than or equal to max-width */
    .svg {
        width: 100%;
    }
}