.sidebar {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    overflow-x: hidden;
    background-color: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    /* transition: width 0.5s; */
    /* padding-top: 60px; */
}

.sidebar-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* background-color: #f1f1f1; */
}

.sidebar-topic,
.sidebar-site-name,
.sidebar-item {
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 1.2rem;
    /* font-weight: 400; */
    line-height: 1.2em;
}

.sidebar-topic,
.sidebar-item {
    border-bottom: solid 1px lightgray;
}

.sidbar-close-btn:hover,
.sidebar-site-name:hover,
.sidebar-item:hover {
    background-color: #cbd0d8;
    color: white;
    text-decoration: none;
}

/* .sidebar-item:active {
    background-color: green;
} */

.sidebar-active-item {
    /* border: 1px solid teal; */
    /* box-shadow: inset 0 0 0 4px lightgray; */
    /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); */
    background-color: #e0e0e0;
}

.sidebar-site-name {
    width: 100%;
    /* height: 100%; */
    padding-top: 15px;
}

.sidbar-close-btn {
    padding: 5px 10px;
}

.sidebar-item-title {
    text-align: center;
    border-bottom: solid 1px lightgray;
}


/* .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    display: block;
    color: rgba(0, 0, 0, 0.87);
    transition: 0.3s;

} */

/* Change the link color on hover */





.sidebar-ribbon {
    height: 50px;
    /* background-color: goldenrod; */
    color: white;

}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


#sidenav-open {

    width: 250px;
}

/* .sidebar-topic {

     background-color: lightgray; 
} */

@media screen and (max-width: 450px) {
    /* .sidenav {
        padding-top: 15px;
    } */

    .sidenav a {
        font-size: 18px;
    }

}