.font-sm {
    font-size: 0.8rem;
}

.font-md {
    font-size: 1rem;
}

.font-md2 {
    font-size: 1.2rem;
}

.font-md4 {
    font-size: 1.4rem;
}

.font-md6 {
    font-size: 1.6rem;
}

.font-lg {
    font-size: 2rem;
}

.font-xlg {
    font-size: 3rem;
}

.font-xxlg {
    font-size: 5rem;
}

.font-xxxl {
    font-size: 16rem;
}