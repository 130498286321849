/*#region -- PARDING --*/
.padding-zero {
    padding: 0;
}

.padding-sm {
    padding: 5px;
}


.padding-m {
    padding: 10px 15px;
}

.padding-md {
    padding: 20px;
}

.padding-top50 {
    padding-top: 50px;
}

/*#endregion*/