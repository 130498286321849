.width-100p {
    width: 100%;
}

.width-90p {
    width: 90%;
}

.width-98vw {
    max-width: 80vw;
}

.col-50p {
    max-width: 50%;
}

.width-80p {
    width: 80%;
}