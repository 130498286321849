/* https://materializecss.com/color.html */

/* 
n: a ligher version of the original color.
n7: is equal to negative 7 
on the color scale 
*/

/* bg {
    color: #5a5a5a;
} */

/*    HOVER */

.bg-hover-red:hover {
  background-color: red;
}

.bg-teal {
  background-color: teal;
}

.bg-teal-light1 {
  background-color: #26a69a;

}

.bg-teal-light2 {
  background-color: #4db6ac;

}

/*#region      COLOR-BACKGROUND     */

.bg-red {
  background-color: red;
}

.bg-darkRed {
  background-color: darkred;
}

.bg-green {
  background-color: #4caf50;
}

.bg-bz-green {
  background-color: #3e8e41;
}

.bg-inherit {
  background-color: inherit;
}

.bg-logo-green {
  background-color: #27AE60;
}

.bg-logo-green-n7 {
  background-color: #cdf4dd;
}

.bg-orange {
  background-color: #f59561;
}

.bg-blue {
  background-color: #9fc9e1;
}

.bg-blue-4 {
  background-color: #6f8c9d !important;
}

.bg-white {
  background-color: white;
}

.bg-lightGray {
  /* background-color: #e8e8e8; */
  background-color: #eee;

}



.bg-chartreuse {
  background-color: chartreuse;
}

.bg-darkorange {
  background-color: darkorange;
}

.bg-fuchsia {
  background-color: fuchsia;
}

.bg-gold {
  /* #FFD700 */
  background-color: gold;
}

.bg-darkturquoise {
  background-color: darkturquoise;
}

.bg-gray {
  background-color: gray;
}

.bg-gray-d3 {
  background-color: #D3D3D3;
}

.bg-crimson {
  background-color: crimson;
}

/* PINT PALETTE */

.bg-pink-pink1 {
  background-color: #f8b195;
}

.bg-pink-pink2 {
  background-color: #f67280;
}

.bg-pink-pink3 {
  background-color: #c06c84;
}

.bg-pink-purple {
  background-color: #6c5b7b;
}

.bg-pink-blue {
  background-color: #355c7d;
}

/*#endregion*/

.bg-pink {
  background-color: pink;
}

.bg-pink-n1 {
  background-color: #ec407a;
}

.bg-pink-n2 {
  background-color: #f06292;
}

.bg-lime {
  background-color: #cddc39;
}

.bg-purple-n1 {
  background-color: #ab47bc;
}

.bg-indigo-n2 {
  background-color: #7986cb;
}

.bg-orange-n1 {
  background-color: #ffa726;
}

.bg-blueGray {
  background-color: #78909c;
}

.bg-black {
  background-color: black;
}

/*#region RGBA */
.bg-white-03 {
  background: rgba(255, 255, 255, 0.3);
}

.bg-white-01 {
  background: rgba(255, 255, 255, 0.1);
}

.bg-slategray {
  background-color: slategray;
}

/* #endregion */