.page-not-found-bg-image {
    max-height: 70vh;
    position: absolute;
}

.page-not-found-bg {
    background-color: rgb(179, 179, 179);
}

.page-not-found-text {
    position: relative;
}