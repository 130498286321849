/* ACTIVE */
/* .btn:active {
  border-style: outset;
  border: none;
} */

/*#region BELLAZOOK */
/* .bz-btn {
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border: none;
  border-radius: .25rem;

  line-height: 1.5;
  padding: .375rem .75rem;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
} */

.bz-btn {
  margin: 2px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 10px;
  font-size: 0.875rem;
  min-width: 40px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 36px;
  box-sizing: border-box;
  line-height: 1.4em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  color: inherit;
  cursor: pointer;
  border: 0;
  display: inline-flex;
  outline: none;
  position: relative;
  user-select: none;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.btn-default:hover,
.bz-btn:hover {
  background-color: #ecc3de;
  color: black;
}

.btn-default:active,
.bz-btn:active {
  background-color: #a8eb0d;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


/* #endregion */

/*#region  HOVER                    */

.btn-hover-green button:hover {
  background-color: green;
}

.btn-hover-orange button:hover {
  background-color: orange;
}

.btn-hover-white button:hover {
  background-color: white;
}

/*#endregion*/

/*#region      BUTTON    */

.btn-clear {
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
  margin: 1px;
}

.btn-min-width-lg {
  min-width: 200px;
}

.btn-3d {
  font-weight: 700;
  margin-bottom: 10px;
  box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 2px 0 0 #adadad, 0 2px 0 1px rgba(0, 0, 0, 0.4), 0 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.btn-blue {
  background-color: #9fc9e1;
  color: white;
}

.btn-white {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 2px;
}

.btn-gray {
  /*border: outset #dddddd;*/
  background-color: white;
  color: rgb(1, 4, 20);
  /*box-shadow: 0 9px #999;*/
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
  margin: 2px;
}

/* .btn-default {
  margin-bottom: 10px;
  box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 2px 0 0 #adadad, 0 2px 0 1px rgba(0, 0, 0, 0.4), 0 2px 2px 1px rgba(0, 0, 0, 0.5);
  background-color: #fff;
} */

.btn-success {
  box-shadow: 0 0 0 1px #5cb85c inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #4cae4c, 0 8px 0 1px rgba(0, 0, 0, 0.4), 0 8px 8px 1px rgba(0, 0, 0, 0.5);
  background-color: #5cb85c;
}

.btn-minWidth {
  min-width: 200px;
}

.btnflat {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border-radius: 4px;
}

/*#endregion*/