.unselectable-text {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-center {
    text-align: center
}

.text-decoration-none {
    text-decoration: none;
}

.text-align-left {
    text-align: left;
}