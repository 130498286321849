/*#region      BORDERS */
.border-teal {
  border-color: teal;
}


.border-solid-ms {
  border: 1px solid lightgray;
}

.border-blue {
  border-color: #9fc9e1;
}

.border-radius-xs {
  border-radius: 0.25rem;
}

.border-radius-md {
  border-radius: 2rem;
}

.border-bottom-right-radius {
  border-bottom-right-radius: 4px !important;
}

/*#endregion*/

.border-solid-blue {
  border: 1px solid blue;

}