/*#region SHARDOW */
/* // Lisa Frank Color Palette
// $pink:          #F83E7F;
// $orange:        #ff7602;
// $yellow:        #fefa27;
// $green:         #509916;
// $blue:          #00b2f0;
// $plum:          #7c2583;
// $purple:        #752a8f;
// $black:         #000; */
.shardow-btn {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.shardow-btn:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shardow-btn:active {
    box-shadow: none;
}

.shardow-sm {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.shardow-gold-sm {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    box-shadow: 0 2px 4px 0 gold, 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.card-shardow {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.card-shardow-black {
    box-shadow: 0px 1px 5px 0px black, 0px 2px 2px 0px black, 0px 3px 1px -2px black;
}

.card-shardow-pink {
    box-shadow: 0px 1px 5px 0px #F83E7F, 0px 2px 2px 0px #00b2f0, 0px 3px 1px -2px black;
}

.card-shardow-pink-md {
    box-shadow: 0px 2px 10px 0px #F83E7F, 0px 4px 4px 0px black, 0px 6px 2px -4px black;
}

.card-shardow-blue {
    box-shadow: 0px 2px 10px 0px #00b2f0, 0px 4px 4px 0px black, 0px 6px 2px -4px black;
}

.card-shardow-blue:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-shardow-blue:active {
    box-shadow: none;
}

.card-shardow-green {
    box-shadow: 0px 2px 10px 0px darkgreen, 0px 4px 4px 0px black, 0px 6px 2px -4px black;
}

/* .card-shardow-green {
    box-shadow: 0px 1px 5px 0px #509916, 0px 2px 2px 0px #509916, 0px 3px 1px -2px black;
} */

.material-shardow {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.material-card-1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

/* .material-card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  } */
.material-card-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.material-card-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.material-card-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.material-card-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/*#endregion*/