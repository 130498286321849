/*#region -- HEIGHT --*/

.height-sm {
    height: 60px;
}

.full-height {
    height: 100%;
}

.fullHeight {
    min-height: 88vh;
}

.height-80vh {
    min-height: 78vh;
}

.height-full {
    height: 100%;
}

.height-svg-max {
    max-height: 90vh;
}

.height-90vh {
    max-height: 90vh;
}

/*#endregion*/