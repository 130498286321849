.text-shardow-sm {
    text-shadow: 2px 1px 2px #000000;
  }
  /* .text-shardow-md {
    text-shadow: 0px 1px 0px #000000;
  } */
  /* .text-shardow-md-fuchsia {
    text-shadow: 3px 3px 10px lightblue;
  }
  .text-shardow-md-darkorange {
    text-shadow: 3px 3px 10px lightblue;
  } */
