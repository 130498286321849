@import 'a-HTML-Elements.css';
@import 'background-color.css';
@import 'background-gradient.css';
@import 'border.css';
@import 'button.css';
@import 'color.css';
@import 'container.css';
@import 'cssGrid.css';
@import 'cursor.css';
@import 'flexBox.css';
@import "font-size.css";
@import "font.css";
@import "height.css";
@import 'list.css';
@import "margin.css";
@import "padding.css";
@import "pointer.css";
@import "select.css";
@import 'shadow-box.css';
@import 'shadow-text.css';
@import 'svg.css';
@import 'text.css';
@import 'width.css';


/*#region      TEMPLATE   */






.opacity-zero {
  opacity: 0;
}

/*#endregion*/

/* @import url("../components/button/styles/button.css"); */

/* ====================================================*/

/* ==========  REGION COPY AND PASTE ABOVE ============*/

/* ====================================================*/

/*#region      BUTTON BORDER --*/

.btn-outline-teal {
  color: teal;
  background-color: transparent;
  background-image: none;
  border-color: teal !important;
}

/*#endregion*/

/*#region -- COLOR-FONT --*/

.color-blue-p {
  color: blue;
}

.color-red-p {
  color: red;
}

.color-orange-p {
  color: orange;
}

.color-green-p {
  color: green;
}

.color-dark-teal {
  /* color: #003333; */
  color: rgb(0, 72, 72);
}

.color-blue {
  color: #9fc9e1;
}

.color-blue-2 {
  color: #8fb4ca
}

.color-blue-3 {
  color: #7fa0b4
}

.color-blue-4 {
  color: #6f8c9d
}

.color-blue-5 {
  color: #1f282d;
}

.color-orange {
  color: #f59561;
}

.color-pink {
  color: #e08283;
}

.color-red {
  color: #f56464;
}

.color-green {
  color: #c2f564;
}

.color-purple {
  color: #be90d4;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.color-teal {
  color: teal;
}

.color-transparent {
  color: transparent;
}

/*#endregion*/

/*#region      DISPLAY   */

.displayNone {
  display: none;
}

.displayFlex {
  display: flex;
}

/*#endregion*/

/*#region      DROPDOWN   */

/* <div class="navbar">
  <a href="#home">Home</a>
  <a href="#news">News</a>
  <div class="dropdown">
    <button class="dropbtn">Dropdown 
      <i class="fa fa-caret-down"></i>
    </button>
    <div class="dropdown-content">
      <a href="#">Link 1</a>
      <a href="#">Link 2</a>
      <a href="#">Link 3</a>
    </div>
  </div> 
</div> */

/*#endregion*/

/*#region      FONT-WEIGHT   */

.font-weight6 {
  font-weight: 600;
}

/*#endregion*/

/*#region      ICON   */

.icon-md {
  font-size: 1.2rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xlg {
  font-size: 3rem;
}

/*#endregion*/

/*#region      MARGIN --*/

.margin-sm {
  margin: 5px;
}

.margin-xsm {
  margin: 2px;
}

.margin-md {
  margin: 15px;
}

.margin-bottom-md {
  margin-bottom: 15px;
}

.margin-top-lg {
  margin-top: 50px;
}

.margin-top-sm {
  margin-top: 1rem;
}

/*#endregion*/

/*#region      SITE   */


/*#endregion*/

/* ====================================================*/

/* MOVE TO THE TOP WHEN VERIFIED */

/* ====================================================*/

/*#region -- COLOR-BORDER --*/

.border-blue {
  border: solid #9fc9e1;
}

.border-orange {
  border: solid #f59561;
}

.border-ridge {
  border: ridge 2px;
}

.border-inset {
  border: inset 5px white;
}

/*#endregion*/
/*#region -- WIDTH --*/

.width-sm {
  width: 120px;
}

.width-md {
  width: 140px;
}

.width-lg {
  width: 250px;
}

.width-xlg {
  width: 300px;
}

/*#endregion*/

/*#region -- Z-INDEX --*/

/* user with position atribute */

.zIndex-1st {
  z-index: 10;
}

.zIndex-2nd {
  z-index: 20;
}

.zIndex-3nd {
  z-index: 30;
}

.zIndex-max {
  z-index: 100;
}

/*#endregion*/

/*#region -- POSITION --*/

.position-relative {
  position: relative;
}


.position-absolute {
  position: absolute;
}

/*#endregion*/

.disabled-link {
  pointer-events: none;
}

.font-style {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

/*#region -- POSITION --*/

/*#endregion*/

hidden {
  visibility: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.selected {
  background-color: green;
  color: white;
}