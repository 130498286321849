.navbar-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* background-color: #333; */
}

.siteRibbon {
    position: fixed;
    width: 100%;
    color: white;
    height: 50px;
    z-index: 1000;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 0 10px;
}

.navbar-width-calc {
    width: calc(100% - 250px);
}

.sidebar-btn {
    margin: 0 10px;
}

.siteRibbon-width {
    width: 100%;
}

@media screen and (max-width: 450px) {

    .navbar-width-calc {
        width: 100%;
    }
}