.margin-top-md {
    margin-top: 20px;
}

.margin-top-lg {
    margin-top: 50px;
}

.margin-xxs {
    margin: 2px;
}

.margin-sm {
    margin: 10px;
}

.margin-md {
    margin: 20px;
}

.margin-zero {
    margin: 0;
}

.margin-height-md {
    margin: 20px 0;
}

.margin-bottom-md {
    margin-bottom: 20px;
}

.margin-zero {
    margin: 0;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}