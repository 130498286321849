.site-header {
    /* text-align: center;
    min-height: 53rem; */
    /* margin-right: 0;
    margin-left: 0; */
    margin: 0;

}

.site-header-small {
    text-align: center;
    /* height: 5rem; */
    /* padding-top: 1rem; */
}

.site-header-footer {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.site-header-title {
    font-family: 'Architects Daughter', cursive;
    font-size: 7rem;
    font-weight: 500;
}

.site-header-text {
    font-family: 'Architects Daughter', cursive;
    padding: 20px;
    position: relative;
    color: white;
    z-index: 10;
}

.site-header-intro {
    font-size: 2.5rem;
}

.site-header-padding {
    margin: 0px 20px 50px 60px;
}

@media screen and (max-width: 768px) {

    /* Apply to screen with less than or equal to max-width */
    .site-header-title {
        font-size: 2.5rem;
    }

    .site-header-intro {
        font-size: 1.5rem;
    }

    .site-header-padding {
        margin: 20px 20px 20px 40px;
    }

    /* .site-header-footer {
        margin-top: 15px;
    } */
}