#site-content {
    /* display: flex; */

    /* background-color: #fafafa; */
    min-height: 92vh;
}

.siteContent-margin-left {
    margin-left: 250px;
}

/* .site-body {
    background-color: #eee;

    transition: margin-left .5s;
    padding: 16px;
} */

@media screen and (max-width: 450px) {

    .siteContent-margin-left {
        margin-left: 0px;
    }
}