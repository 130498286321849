.navlink {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* .navlink:hover:not(.active) { */
.navlink:hover {
    background-color: #fff;
    color: teal;
    text-decoration: none;
}