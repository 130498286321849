/*#region -- SITE GRID --*/

.site-grid-container {
    display: grid;
    grid-template-rows: auto auto;
    /* grid-gap: 10px; */
}

.site-grid-nav {
    grid-row-start: 1;
    grid-row-end: 2;
}

.site-grid-nav-body {
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 50px;
    /* padding-top: 10px; */
    min-height: 90vh;
}

.site-grid-footer {
    grid-row-start: 3;
    grid-row-end: 4;
    padding-top: 10px;
}

/*#endregion*/

/*#region    GRID TEN BY TEN  --*/

.gridWrapper {
    display: grid;
    grid-gap: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gridCol_10 {
    grid-template-rows: 10% auto 10%;
}

.row1 {
    grid-area: 1;
}

.row2 {
    grid-area: 2;
}

.row3 {
    grid-area: 3;
}

.row4 {
    grid-area: 4;
}

.row5 {
    grid-area: 5;
}

.row6 {
    grid-area: 6;
}

.row7 {
    grid-area: 7;
}

.row8 {
    grid-area: 8;
}

.row9 {
    grid-area: 9;
}

.row10 {
    grid-area: 10;
}

.row11 {
    grid-area: 11;
}

/*     COLUMN  */

.column1 {
    grid-column: 1;
}

.column2 {
    grid-column: 2;
}

.column3 {
    grid-column: 3;
}

.column4 {
    grid-column: 4;
}

.column5 {
    grid-column: 5;
}

.column6 {
    grid-column: 6;
}

.column1 {
    grid-column: 1;
}

.column7 {
    grid-column: 7;
}

.column8 {
    grid-column: 8;
}

.column9 {
    grid-column: 9;
}

.column10 {
    grid-column: 10;
}

/*#endregion*/

.grid-three-col {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    justify-content: center;
    grid-gap: 5px;
}