/*#region      GRADIENT   */

.bg-blue-lightgray {
    background: #9fc9e1;
    background: -webkit-linear-gradient(to top, #e8e8e8, #9fc9e1);
    background: linear-gradient(to top, #e8e8e8, #9fc9e1);
}

.bg-teal-lightgray {
    background: teal;
    background: -webkit-linear-gradient(to top, #e8e8e8, teal);
    background: linear-gradient(to top, #e8e8e8, teal);
}

.bg-black-teal {
    background: teal;
    background: -webkit-linear-gradient(to top, #003333, teal);
    background: linear-gradient(to top, #003333, teal);
}

.bg-teal-gray {
    background: teal;
    background: -webkit-linear-gradient(to top, #e8e8e8, teal);
    background: linear-gradient(to top, #e8e8e8, teal);
}

.bg-teal-pink {
    background: teal;
    background: -webkit-linear-gradient(to right, pink, teal);
    background: linear-gradient(to right, pink, teal);
}

/*#endregion*/

/*#region   TEAL   ================================== */
.bg-teal-pink {
    background: teal;
    background: -webkit-linear-gradient(to top, pink, teal);
    background: linear-gradient(to top, pink, teal);
}

/*#endregion*/

/*#region   Pink   ================================== */
.bg-pink-teal {
    background: pink;
    background: -webkit-linear-gradient(to top, teal, pink);
    background: linear-gradient(to top, teal, pink);
}

/*#endregion*/