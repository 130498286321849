/* https://www.colorbox.io/#steps=11#hue_start=359#hue_end=4#hue_curve=easeInSine#sat_start=4#sat_end=65#sat_curve=easeOutQuad#sat_rate=130#lum_start=80#lum_end=70#lum_curve=easeInOutQuad */

/* #region   BellaZook COLORS   ======================================*/
/* https://www.colorbox.io/#steps=14#hue_start=306#hue_end=0#hue_curve=easeInOutQuad#sat_start=4#sat_end=90#sat_curve=easeOutQuad#sat_rate=130#lum_start=100#lum_end=80#lum_curve=easeInQuad */
.color-bz-pink1 {
    color: #fff2fe;
}

.color-bz-pink2 {
    color: #fec8fd;
}

.color-bz-pink3 {
    color: #ef9ffd;
}

.color-bz-purple1 {
    color: #bf78fc;
}

.color-bz-purple2 {
    color: #6556fa;
}

.color-bz-blue1 {
    color: #3896f8;
}

.color-bz-blue2 {
    color: #1ff5d1;
}

.color-bz-green1 {
    color: #0cf128;
}

.color-bz-green2 {
    color: #7ded00;
}

.color-bz-yellow {
    color: #e8d900;
}

.color-bz-orange1 {
    color: #e27800;
}

.color-bz-orange2 {
    color: #db3700;
}

.color-bz-red1 {
    color: #d31000;
}

.color-bz-red2 {
    color: #cc0000;
}

/* #endregion */



.color-darkgray {
    color: #303030;
}

.color-twitter-blue {
    color: #5fb4e9;
}

.color-gray {
    color: gray
}

.color-darkGray {
    color: #505050;
}